import React, { useEffect, useState, useMemo } from 'react';
import { ContactFormSection as ContactFormSectionDS } from '@solublestudio/legalbrave-design-system';
import { Config } from 'api-base/lib/types';
import { useUtms } from '../../hooks/useUtms';

import { useRouter } from 'next/router';

const ContactFormSection = ({
    globalConfig,
    ...props
}: {
    globalConfig: Config;
    formId?: string;
}) => {
    const [cookies] = useUtms(globalConfig.web.cookies.utms);

    const onSubmit = async (values) => {
        const response = await fetch('api/contact-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        });

        if (response.status === 200) {
            // Tracking events
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contact_form',
            });

            return;
        }

        throw Error('Error trying to register User, please try again');
    };

    return (
        <ContactFormSectionDS
            {...props}
            contactForm={{
                onSubmit,
            }}
        />
    );
};

export default ContactFormSection;
